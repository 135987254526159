var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-add pa-10"},[_c('title-page',{attrs:{"icon":"ico-users"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.edit ? 'user.edit' : 'user.add'))+" ")]),_c('v-breadcrumbs',{attrs:{"items":[
          {text: _vm.$t('user.users'), to: {name: 'user.search'}, exact: true },
          {text: _vm.$t(_vm.edit ? 'user.edit' : 'user.add') },
      ],"divider":">"}}),(!_vm.edit || _vm.user.id)?[_c('v-form',{ref:"form",staticClass:"form-grid",on:{"submit":function($event){$event.preventDefault();return _vm.sendData.apply(null, arguments)}}},[_c('headline',[_vm._v(_vm._s(_vm.$t('user.account')))]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('form-field',{attrs:{"label":_vm.$t('firstname')},model:{value:(_vm.user.firstname),callback:function ($$v) {_vm.$set(_vm.user, "firstname", $$v)},expression:"user.firstname"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('form-field',{attrs:{"label":_vm.$t('lastname')},model:{value:(_vm.user.lastname),callback:function ($$v) {_vm.$set(_vm.user, "lastname", $$v)},expression:"user.lastname"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('form-field',{attrs:{"label":_vm.$t('email'),"input-type":"email","rules":[
                _vm.commonRules.email,
                _vm.commonRules.required,
              ]},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('form-field',{attrs:{"type":"select","label":_vm.$t('role'),"items":_vm.roles,"rules":[
                _vm.commonRules.required
              ]},model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('form-field',{attrs:{"type":"tree-select-organization","label":_vm.$t('organization.organization'),"multiple":"","rules":[
                _vm.commonRules.required
              ]},model:{value:(_vm.user.organizations),callback:function ($$v) {_vm.$set(_vm.user, "organizations", $$v)},expression:"user.organizations"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('form-field',{attrs:{"label":_vm.$t('main-language'),"type":"available-select","input-type":"language","rules":[
                _vm.commonRules.required,
              ]},model:{value:(_vm.user.language),callback:function ($$v) {_vm.$set(_vm.user, "language", $$v)},expression:"user.language"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('form-field',{attrs:{"label":_vm.$t('user.active-account'),"type":"switch"},model:{value:(_vm.user.enabled),callback:function ($$v) {_vm.$set(_vm.user, "enabled", $$v)},expression:"user.enabled"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-btn',{attrs:{"type":"submit","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)],1),(_vm.edit)?_c('div',[_c('headline',{staticClass:"mt-10"},[_vm._v(_vm._s(_vm.$t('user.security')))]),_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-btn',{attrs:{"type":"button"},on:{"click":_vm.resetPassword}},[_vm._v(" "+_vm._s(_vm.$t('user.reset-password'))+" ")])],1)],1)],1):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }