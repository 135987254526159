<template>
  <div class="user-add pa-10">
    <title-page icon="ico-users">
      {{ $t(edit ? 'user.edit' : 'user.add') }}
    </title-page>


    <v-breadcrumbs
        :items="[
            {text: $t('user.users'), to: {name: 'user.search'}, exact: true },
            {text: $t(edit ? 'user.edit' : 'user.add') },
        ]"
        divider=">"
    ></v-breadcrumbs>


    <template v-if="!edit || user.id">
      <v-form ref="form" class="form-grid" @submit.prevent="sendData">
        <headline>{{ $t('user.account') }}</headline>
        <v-row>
          <v-col cols="12" md="6">

            <form-field
                :label="$t('firstname')"
                v-model="user.firstname"
            />
          </v-col>
          <v-col cols="12" md="6">
            <form-field
                :label="$t('lastname')"
                v-model="user.lastname"

            />
          </v-col>
          <v-col cols="12" md="6">
            <form-field
                :label="$t('email')"
                v-model="user.email"
                input-type="email"
                :rules="[
                  commonRules.email,
                  commonRules.required,
                ]"
            />
          </v-col>

          <v-col cols="12" md="6">
            <form-field
                type="select"
                :label="$t('role')"
                v-model="user.role"
                :items="roles"
                :rules="[
                  commonRules.required
                ]"
            />
          </v-col>
          <v-col cols="12" md="6">
            <form-field
                type="tree-select-organization"
                :label="$t('organization.organization')"
                v-model="user.organizations"
                multiple
                :rules="[
                  commonRules.required
                ]"
            />
          </v-col>
          <v-col cols="12" md="6">
            <form-field
                :label="$t('main-language')"
                type="available-select"
                input-type="language"
                v-model="user.language"
                :rules="[
                  commonRules.required,
                ]"
            />
          </v-col>
          <v-col cols="12" md="6">
            <form-field
                :label="$t('user.active-account')"
                v-model="user.enabled"
                type="switch"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-center">
            <v-btn type="submit" color="primary">
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <div v-if="edit">
        <headline class="mt-10">{{ $t('user.security') }}</headline>
        <v-row>
          <v-col class="text-center">
            <v-btn type="button" @click="resetPassword">
              {{ $t('user.reset-password') }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </template>

  </div>
</template>

<script>
import RulesMixin from "@mixins/RulesMixin";
import SecurityRepository from "@repository/SecurityRepository";
import PrevRouteMixin from "@mixins/PrevRouteMixin.vue";

export default {

  data() {
    return {
      roles: [],
      user: {
        firstname: null,
        lastname: null,
        email: null,
        enabled: true,
        organizations: null,
        role: 'CONTROL',
        language: 'fr',
      },
    }
  },

  mixins: [
    PrevRouteMixin,
    RulesMixin,
  ],

  mounted() {
    if(this.edit) {
      this.loadData();
    } else {
      const {oid} = this.$route.query
      if(oid) {
        this.user.organizations = [oid]
      }
    }
    this.loadRoles()
  },

  computed: {
    edit() {
      return !!this.$route.params.id
    },
  },


  watch: {
    user: {
      handler() {
        this.setUnsavedChanges()
      },
      deep: true
    },

    '$route': {
      handler() {
        this.$refs.form.reset()
      },
      deep: true,
    },
  },

  methods: {

    loadRoles() {
      SecurityRepository.roles().then(roles => {
        this.roles = roles.map(r => ({
          text: this.$t('enums.role.' + r),
          value: r,
        }))
      }).catch(err => {
        this.$dialog.notify.error(err.response.data?.message || err.message)
      })
    },

    resetPassword() {
      SecurityRepository.resetPassword(this.user.email).then(() => {
        this.$dialog.notify.success(this.$t('user.reset-password-sended'))
      }).catch(err => {
        this.$dialog.notify.error(err.response.data?.message || err.message)
      })
    },

    loadData() {
      this.showLoading(true)
      let id = this.$route.params.id
      if(id) {
        SecurityRepository.get(this.$route.params.id).then(user => {
          this.user = user
          this.$nextTick(() => {
            this.cleanUnsavedChanges()
          })
          this.showLoading(false)
        }).catch(err => {
          this.$dialog.notify.error(err.response.data?.message || err.message)
          this.showLoading(false)
        })
      }
    },

    sendData() {
      if(!this.user.organizations || this.user.organizations.length === 0) {
        this.$dialog.notify.error(this.$t('organization.organization') + ' : ' +  this.$t('rules.field-required'))
        return ;
      }
      if(this.$refs.form.validate()) {
        this.showLoading(true)
        if(this.edit) {
          SecurityRepository.update(this.$route.params.id, this.user).then(result => {
            this.$dialog.notify.success(this.$t("updated-successfully"))
            this.cleanUnsavedChanges()
            this.$router.push({name: 'user.search'})
            this.showLoading(false)
          }).catch(err => {
            this.$dialog.notify.error(err.response.data?.message || err.message)
            this.showLoading(false)
          })
        } else {
          SecurityRepository.create(this.user).then(result => {
            this.$dialog.notify.success(this.$t("created-successfully"))
            this.cleanUnsavedChanges()
            this.$router.push({name: 'user.search'})
            this.showLoading(false)
          }).catch(err => {
            this.$dialog.notify.error(err.response.data?.message || err.message)
            this.showLoading(false)
          })
        }
      }
    },


  }
}
</script>

<style lang="scss">

</style>
