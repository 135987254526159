<template>
  <div class="user-search pa-10">
    <title-page icon="ico-users">
      {{ $t("user.search-user") }}
    </title-page>


    <v-row >
      <v-col class="text-right">
        <v-btn :to="{name: 'user.add'}">
          <v-icon left>mdi-alert-plus-outline</v-icon>
          {{ $t('user.add')}}
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <search-field
            class="mt-10"
            solo
            :search.sync="searchText"
            :placeholder="$t('user.search-user')"
            prepend-inner-icon="ico-magnify"
        >
        </search-field>
        <filter-by-letter
            :selectedLetter="startBy"
            @update:startBy="updateStartBy"
        />
      </v-col>
    </v-row>

    <v-row no-gutters class="mt-10">
      <v-col>



        <v-data-table
            class="user-list"
            :headers="headers"
            :loading="loading"
            :items="users"
            :options.sync="options"
            :server-items-length="total"
            @click:row="row => $router.push({name: 'user.edit', params: {id: row.id}})"
        >
          <template #item.enabled="{value}">
            {{ value ? $t('yes') : $t('no') }}
          </template>
          <template #item.role="{value}">
            {{ $t('enums.role.' + value) }}
          </template>

          <template #item.organizations="{value}">
            <span class="organization-item" v-for="organization in value" :key="organization.id">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span
                      v-bind="attrs"
                      v-on="on"
                  >
                    {{ organization.name }}
                  </span>
                </template>
                <span>{{ organization.path }}</span>
              </v-tooltip>

            </span>
          </template>

        </v-data-table>

      </v-col>
    </v-row>

  </div>
</template>

<script>
import UserRepository from "@repository/SecurityRepository";
import FilterByLetter from "@blocks/FilterByLetter.vue";

export default {
  components: {FilterByLetter},

  data() {
    return {
      loading: false,
      searchText: null,
      selectedItem: null,
      startBy: null,
      options: null,
      total: 0,
      headers: [
        {text: this.$t('organization.organization'), value: 'organizations'},
        {text: this.$t('firstname'), value: 'firstname'},
        {text: this.$t('lastname'), value: 'lastname'},
        {text: this.$t('email'), value: 'email'},
        {text: this.$t('enable'), value: 'enabled'},
        {text: this.$t('role'), value: 'role'},
      ],
      users: [],
    }
  },

  mounted() {

  },

  watch: {
    startBy() {
      this.loadData();
    },
    searchText() {
      this.loadData()
    },

    options() {
      this.loadData()
    },

    selectedItem(val) {
      this.$router.push({name: 'user.edit', params: {id: val.id} })
    },
  },

  methods: {
    updateStartBy(letter) {
      this.startBy = letter;
    },
    loadData() {

      let data = {
        query: this.searchText,
      }
      if (this.options.itemsPerPage > -1) {
        data.offset = (this.options.page * this.options.itemsPerPage) - this.options.itemsPerPage
      }
      data.limit = this.options.itemsPerPage


      if (this.options.sortBy.length > 0) {
        data.orderBy = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0) {
        data.orderDirection = this.options.sortDesc[0] ? 'desc' : 'asc'
      }
      data.startBy = this.startBy
      this.loading = true
      UserRepository.search(data).then(response => {
        this.total = response.total
        this.users = response.items
        this.loading = false
      }).catch(e => {
        this.$dialog.notify.error(e.response?.data?.message || e.message)
        this.loading = false
      })
    }
  },

}
</script>

<style lang="scss">
  .user-search {
    & + .search-item {
      border-top: 1px solid #ccc;
    }
    .user-list {
      td, th {
        cursor: pointer;
        &.text-start {
          text-align: left !important;
        }
        .organization-item {
          color: var(--v-anchor-base);

          & + .organization-item {
            &:before {
              content: ', ';
              color: black;
            }
          }

        }
      }
    }
  }
</style>
